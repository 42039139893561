var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CCard',[_c('CCardHeader',{staticClass:"d-flex align-items-center"},[_c('TMessage',{attrs:{"content":"Product","bold":""}}),_c('TSpinner',{attrs:{"loading":_vm.loading}}),_c('TButton',{staticClass:"ml-auto",attrs:{"content":"Create product","color":"info","icon":"cilPlus"},on:{"click":function($event){_vm.showModalAddProduct = true}}})],1),_c('CCardBody',[_c('TTableAdvance',{attrs:{"items":_vm.list,"fields":_vm.fields,"store":"product.product_affiliate_store_list","resource":"/admin/ecommerce/affiliate-product","enterable":"","reloadable":"","deletable":""},on:{"click-clear-filter":_vm.clearFilter,"click-reload":_vm.fetch},scopedSlots:_vm._u([{key:"image_url",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('SCardProductItems',{attrs:{"item":{
              image_url: item.image_url,
              name: item.name,
              id: item.product_id,
            },"widthAuto":""}})],1)]}},{key:"price",fn:function(ref){
            var item = ref.item;
return [_c('td',[_c('TMessageMoney',{attrs:{"amount":item.price,"currency":item.currency_id || 'JPY'}})],1)]}},{key:"product_id",fn:function(ref){
            var item = ref.item;
return [_c('td',{staticClass:"text-break"},[_c('TMessage',{attrs:{"content":item.product_id,"noTranslate":""}})],1)]}},{key:"initial_price",fn:function(ref){
            var item = ref.item;
return [_c('td',[_c('TMessageMoney',{attrs:{"amount":item.initial_price,"currency":item.currency_id || 'JPY'}})],1)]}},{key:"delivery",fn:function(ref){
            var item = ref.item;
return [_c('td',[_c('TMessage',{attrs:{"content":item.delivery == 'free_delivery'
                ? 'Free delivery'
                : item.delivery == 'anonymous_delivery'
                ? 'Anonymous delivery'
                : 'Empty'}})],1)]}},{key:"categories",fn:function(ref){
                var item = ref.item;
return [_c('td',[_vm._l((item.categories),function(cat){return _c('CBadge',{key:cat.id,staticClass:"mr-1",attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(cat.name)+" ")])}),(item.categories && !item.categories.length)?_c('TMessageNotFound'):_vm._e()],2)]}},{key:"url",fn:function(ref){
                var item = ref.item;
return [_c('td',[_c('TLink',{attrs:{"content":item.url,"to":item.url}})],1)]}},{key:"product_id-filter",fn:function(){return [_c('TInputText',{attrs:{"value":_vm.filter.product_id,"placeholder":"Product Id"},on:{"update:value":[function($event){return _vm.$set(_vm.filter, "product_id", $event)},_vm.filterChange]}})]},proxy:true},{key:"image_url-filter",fn:function(){return [_c('TInputText',{attrs:{"value":_vm.filter.name,"placeholder":"Product name"},on:{"update:value":[function($event){return _vm.$set(_vm.filter, "name", $event)},_vm.filterChange]}})]},proxy:true}])}),_c('AddProductModal',{attrs:{"affiliateStoreId":_vm.affiliateStoreId,"show":_vm.showModalAddProduct},on:{"update:show":function($event){_vm.showModalAddProduct=$event},"created":_vm.filterChange}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }