var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CCard',[_c('CCardHeader',{staticClass:"d-flex align-items-center"},[_c('TMessage',{attrs:{"content":"Category","bold":""}}),_c('TSpinner',{attrs:{"loading":_vm.loading}}),_c('TButton',{staticClass:"ml-auto",attrs:{"icon":"cil-plus","content":"Add category"},on:{"click":function($event){_vm.showModalCate = true}}})],1),_c('CCardBody',{staticClass:"mb-2 w-100 mx-auto",staticStyle:{"max-width":"800px"}},[_c('TTableAdvance',{attrs:{"items":_vm.list,"fields":_vm.itemFields,"store":"product.category_affiliates","resource":"","noFilter":"","reloadable":""},on:{"click-reload":_vm.fetchCategories},scopedSlots:_vm._u([{key:"append-actions",fn:function(ref){
var item = ref.item;
return [_c('CCol',{staticClass:"d-flex px-2",attrs:{"col":"12"}},[_c('TButtonEdit',{on:{"click":function($event){return _vm.editItem(item)}}}),_c('TButtonDelete',{on:{"click":function($event){return _vm.removeCateAffi(item.id)}}})],1)]}},{key:"image_url",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CImg',{staticClass:"mb-3 p-0",staticStyle:{"height":"50px","width":"50px","object-fit":"contain"},attrs:{"thumbnail":"","src":item.image_url,"placeholderColor":""}})],1)]}},{key:"category",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('SMessageRole',{attrs:{"id":item.name}})],1)]}},{key:"parent",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.parent)?_c('TMessageText',{attrs:{"value":item.parent.name,"noTranslate":""}}):_vm._e()],1)]}}])})],1),(_vm.id)?_c('CategoryModal',{attrs:{"show":_vm.showModalCate,"affiliateStoreId":_vm.id},on:{"update:show":function($event){_vm.showModalCate=$event},"created":_vm.fetchCategories}}):_vm._e(),(_vm.id)?_c('CategoryModal',{attrs:{"show":_vm.showEditCategory,"affiliateStoreId":_vm.id,"category":_vm.detail},on:{"updated":function($event){return _vm.fetchCategories()},"update:show":function($event){_vm.showEditCategory=$event}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }